import {FC, RefObject, useState} from "react";
import {
    Button,
    DatePicker,
    DatePickerRef,
    Form,
    ImageUploader,
    Input,
    Selector,
    Stepper,
    Toast
} from "antd-mobile";
import dayjs from 'dayjs'
import Network from "./network";
import axios from "axios";

const Registered: FC = () => {
    const [infoForm] = Form.useForm()

    const [uid, setUid] = useState(0)

    const onPhoneSubmit = async () => {
        let phone = infoForm.getFieldValue("phone");
        if (phone.length !== 11) {
            Toast.show({
                icon: 'fail',
                content: '手机号格式错误',
            })
            return;
        }
        const response = await Network.getInstance().get("/user/web_user_register_status", {
            "params": {
                "phone": phone,
            },
        })
        switch (response.data["comboStatus"]) {
            case 1:
                Toast.show({
                    icon: 'success',
                    content: '查询成功',
                })
                setUid(response.data["uid"] ?? 0)
                break;
            case 4:
                Toast.show({
                    icon: 'fail',
                    content: '已提交病历,等待审核',
                })
                setUid(0)
                break;
            default:
                Toast.show({
                    icon: 'fail',
                    content: '无权限',
                })
                setUid(0)
                break;
        }
    }
    const onInfoSubmit = async (values: any) => {
        values["age"] = `${values["age"]}`;
        values["gender"] = values["gender"][0];
        values["examineTime"] = dayjs(new Date(values["examine_time"])).format('YYYY-MM-DD');
        values["imgUrl"] = (values["imgUrl"] as Array<any>).map(function (element) {
            return element["url"].replace("https://img.oss.simingtang.net/", "")
        }).join(",");
        if (values["amblyopiaType"]) {
            values["amblyopiaType"] = values["amblyopiaType"][0]
        }
        if (values["twoEyesUnevenExtend"]) {
            values["twoEyesUnevenExtend"] = values["twoEyesUnevenExtend"][0]
        }
        if (values["strabismus"]) {
            values["strabismus"] = values["strabismus"][0]
        }
        if (values["auxiliaryInfo"]) {
            values["auxiliaryInfo"] = values["auxiliaryInfo"].join(",")
        }
        if (values["other"]) {
            values["other"] = values["other"].join(",")
        }
        await Network.getInstance().post("/user/web_register_vision_archives", {uid: uid, ...values})
        Toast.show({
            icon: 'success',
            content: '提交成功',
        })
        infoForm.resetFields()
        setUid(0)
    }

    const nowDate = new Date()
    // const ossData = (await Network.getInstance().post("http://192.168.0.102:8091/user/oss_eyesight_img_url_no_token", {
    //     uid: uid
    // })).data
    async function uploadImage(file: File) {
        const ossData = (await Network.getInstance().post("https://registration.hyb.simingtang.net/oldApi/user/oss_eyesight_img_url_no_token", {
            uid: uid
        })).data
        await axios.create().postForm("https://simingtang-img.oss-cn-shanghai.aliyuncs.com", {
            "key": ossData["key"],
            "OSSAccessKeyId": ossData["accessId"],
            "policy": ossData["policy"],
            "Signature": ossData["signature"],
            "success_action_status": "200",
            "file": file,
        })
        return {
            url: `https://img.oss.simingtang.net/${ossData["key"]}`,
        }
    }

    return <>
        <Form layout='vertical'
              form={infoForm}
              mode='card'
              onFinish={onInfoSubmit}
              footer={uid ? <Button block color='primary' type='submit' size='large'>
                  提交病历
              </Button> : <></ >}>
            <Form.Header>查询手机号</Form.Header>
            <Form.Item name='phone' label='手机号'
                       rules={[{required: true}, {min: 11, max: 11, message: "手机号格式不正确"}]}
                       validateTrigger='onBlur' extra={
                <div style={{
                    borderLeft: 'solid 1px #eeeeee',
                    paddingLeft: '12px',
                    fontSize: '17px',
                    lineHeight: '22px',
                }} onClick={onPhoneSubmit}>
                    <a>注册/查询</a>
                </div>
            }>
                <Input type='tel' maxLength={11} placeholder='请输入要注册的手机号' clearable={true} onClear={() => {
                    setUid(0)
                }}/>
            </Form.Item>
            {
                uid ? <> <Form.Header>提交视力档案</Form.Header>
                    <Form.Item name='name' label='用户昵称' rules={[{required: true, message: '请输入用户昵称'}, {
                        min: 2,
                        message: "用户昵称不能少了两个字"
                    }]}>
                        <Input placeholder='请输入用户昵称'/>
                    </Form.Item>
                    <Form.Item name='age' label='孩子年龄' rules={[{required: true, message: '请输入孩子年龄'}, {
                        min: 1,
                        max: 100,
                        message: "年龄格式不正确",
                        type: 'number',
                    }]}>
                        <Stepper formatter={value => `${value} 岁`}
                                 parser={text => parseFloat(text.replace(' 岁', ''))}/>
                    </Form.Item>
                    <Form.Item name='gender' label='孩子性别' rules={[{required: true, message: '请选择孩子性别'}]}>
                        <Selector options={[
                            {label: '男', value: '1'},
                            {label: '女', value: '2'},
                        ]}/>
                    </Form.Item>
                    <Form.Item name='examine_time' label='检查时间'
                               rules={[{required: true, message: '请选择医院检查时间'}]} trigger='onConfirm'
                               onClick={(e, datePickerRef: RefObject<DatePickerRef>) => {
                                   datePickerRef.current?.open()
                               }}>
                        <DatePicker renderLabel={(type: string, data: number) => {
                            switch (type) {
                                case 'year':
                                    return data + '年'
                                case 'month':
                                    return data + '月'
                                case 'day':
                                    return data + '日'
                                case 'hour':
                                    return data + '时'
                                case 'minute':
                                    return data + '分'
                                case 'second':
                                    return data + '秒'
                                default:
                                    return data
                            }
                        }} max={nowDate}
                                    min={new Date(nowDate.getFullYear() - 100, nowDate.getMonth(), nowDate.getDate())}>
                            {value =>
                                value ? dayjs(value).format('YYYY年MM月DD日') : '请选择医院检查时间'
                            }
                        </DatePicker>
                    </Form.Item>

                    <Form.Item name='imgUrl' label='孩子病历'
                               rules={[{required: true, message: '请上传孩子病历'}]}>
                        <ImageUploader
                            upload={uploadImage} maxCount={3}
                            showFailed={false}
                        />
                    </Form.Item>
                </> : <></>
            }
        </Form>
    </>;
}

export default Registered;
