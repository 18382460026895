import SafeArea from 'antd-mobile/es/components/safe-area';
import React from 'react';
import ReactDOM from 'react-dom/client';
import {createHashRouter, RouterProvider} from 'react-router-dom';
import './index.css';
import Registered from "./registered";

const router = createHashRouter([
    {
        path: "*",
        element:<Registered />,
    },
]);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <SafeArea position={"top"}/>
        <RouterProvider router={router}/>
        <SafeArea position={"bottom"}/>
    </React.StrictMode>
);